<template>
  <base-section id="how-security">
    <base-section-heading
      color="primary lighten-2"
      :title="$i18n.t('t1.title')"
      :text="$i18n.t('t1.text')"
    />
    <div class="d-flex justify-center mt-3 mb-12">
      <div
				class="d-flex justify-center flex-wrap"
				style="max-width: 1200px"
			>
        <img
					class="px-2"
					v-for="i in 2"
					:key="i"
          :src="require(`@/assets/imgs/certification-logos/${i}.svg`)"
					width="215px"
				/>
				<img
					class="px-3"
          :src="require(`@/assets/imgs/certification-logos/3.svg`)"
					width="285px"
				/>
				<img
					class="px-2"
					v-for="j in 2"
					:key="j + 3"
          :src="require(`@/assets/imgs/certification-logos/${j + 3}.svg`)"
					width="215px"
				/>
      </div>
    </div>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionSafety',
  }
</script>
<i18n>
{
	"en": {
		"t1": {
			"text": "Our priority is to safeguard both the operators and guests of the beach resorts. For this reason, specific operational procedures for checking the correct dilution and preparation of the solutions have been arranged . Detailed technical-operational instructions have also been provided for the correct implementation of products. The protocol follows standards and guidelines for QUALITY UNI EN ISO 9001:2015 e UNI EN 16636:2015, ENVIRONMENT UNI EN ISO 14001:2015 and SAFETY UNI ISO 45001:2018.",
			"title": "We follow a rigid intervention protocol"
		}
	},
	"it": {
		"t1": {
			"text": "<b>Salvaguardare gli operatori e gli ospiti dello stabilimento balneare è la nostra priorità.</b> Per questo, sono state predisposte specifiche modalità operative per la diluizione e preparazione dei prodotti e dettagliate istruzioni tecnico-operative per il corretto impiego degli stessi.<br><br> Il protocollo è aderente alle normative vigenti e agli standard di <b>QUALITÀ UNI EN ISO 9001:2015 e UNI EN 16636:2015, AMBIENTE UNI EN ISO 14001:2015 e SICUREZZA UNI ISO 45001:2018</b>.<br /><br /> Il buon esito delle operazioni si raggiunge anche attraverso un’attenta ed accurata preparazione degli operatori. Il personale impiegato è costantemente aggiornato sul corretto utilizzo delle attrezzature e dei prodotti, <b>nel pieno rispetto delle normative vigenti sulla sicurezza e del relativo piano formativo</b>.<br><br> Tutte le attività di sanificazione vengono effettuate seguendo i protocolli e le istruzioni operative messe a disposizione da SGD Group. Ci impegniamo a garantire la massima professionalità e serietà nell’esecuzione di ogni intervento, per la sicurezza dell’utente finale.",
			"title": "FORMIAMO IL PERSONALE SECONDO<br /> UN RIGIDO PROTOCOLLO D’INTERVENTO"
		}
	}
}
</i18n>
